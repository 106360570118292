@use 'mixins' as *;

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  overflow: hidden;
  fill: currentColor;
  stroke: none;
  pointer-events: none;
}

.hide-mobile {
  display: none;

  @include up('medium') {
    display: block;
  }
}

.hide-desktop {
  display: block;

  @include up('medium') {
    display: none;
  }
}

.hidden {
  display: none !important;
}

.bg-primary {
  background: var(--gradient-primary);
}

.bg-black {
  background: var(--color-black);
}
