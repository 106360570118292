@use 'mixins' as m;

:root {
  // colors
  --color-white: #fff;
  --color-black: #000;
  --color-primary: #32d9ff;
  --color-error: #ee2f3f;
  --color-warning: #ffcb5c;
  --color-success: #31af59;

  // gradients
  --gradient-primary: linear-gradient(77deg, #03aeed 0.95%, #5cf4ff 97.71%);

  // transitions
  --easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --transition-duration: 0.2s;

  // general
  --container-width: 90rem; // 1440px
  --content-padding: 1.5rem;
  --font-archivo: 'Archivo', sans-serif;
}
