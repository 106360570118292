@use '../../assets/styles/mixins' as *;

$className: 'footer';

.#{$className} {
  background: var(--color-black);

  &-content {
    display: flex;
    gap: 0.75rem 1.75rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-block: 1.3rem;
    color: var(--color-white);
    font-size: 0.75rem;
    font-weight: 700;
  }

  &__logo {
    width: 1rem;
  }

  @include up('medium') {
    &-content {
      gap: 0.75rem 3rem;
      align-items: center;
      justify-content: start;
      padding-block: 2.5rem;
      font-size: 1.5rem;
    }

    &__logo {
      width: 1.5rem;
    }

    &__copy {
      margin-right: auto;
    }
  }
}
