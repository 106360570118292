@use '../../assets/styles/mixins' as *;

.contact {
  display: grid;
  grid-template-columns: 1fr;
  gap: rem(100px);
  padding-top: rem(60px);
  padding-bottom: rem(80px);

  @include up('medium') {
    grid-template-columns: 1fr 1fr;
    padding-top: rem(120px);
    padding-bottom: rem(150px);
  }

  &__logo {
    img {
      width: 100%;
      margin-inline: auto;
      max-width: rem(140px);

      @include up('medium') {
        margin-inline: auto 0;
        max-width: rem(250px);
      }
    }
  }

  &__data {
    font-weight: 600;
    font-size: 1.25rem;

    h2 {
      color: var(--color-white);
      font-size: 3rem;
      margin-bottom: 2.5rem;

      @include up('medium') {
        margin-bottom: 3.75rem;
      }
    }

    &--logo {
      width: rem(170px);
      margin-bottom: 2rem;

      @include up('medium') {
        margin-bottom: 2.5rem;
      }
    }

    &--address {
      color: var(--color-white);
      margin-bottom: 2.5rem;

      @include up('medium') {
        margin-bottom: 3.75rem;
      }
    }

    &--getintouch {
      color: var(--color-white);
      margin-bottom: 1.25rem;
    }
  }
}
