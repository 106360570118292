@use 'mixins' as *;

a {
  color: var(--color-black);
  transition: color var(--transition-duration) var(--easing);
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: var(--color-white);
      text-decoration: underline;
    }
  }

  footer & {
    color: var(--color-white);

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
