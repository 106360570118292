@use '../../assets/styles/mixins' as *;

$className: 'header';

.#{$className} {
  position: relative;
  background: var(--gradient-primary);
  padding-top: 2rem;
  padding-bottom: 24rem;
  overflow-x: clip;

  @include up('medium') {
    padding-top: rem(65px);
    padding-bottom: 10rem;
  }

  &__logo {
    display: block;

    img {
      width: rem(170px);
    }
  }

  h1 {
    color: var(--color-white);
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 5rem;
    margin-bottom: 3rem;
    max-width: 44rem;
    line-height: 1.2;

    @include up('medium') {
      font-size: 3rem;
      margin-top: rem(135px);
    }
  }

  &__contact-us {
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration-line: underline;
  }

  .wrap-large {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .syringe {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 30%);
    width: min(90%, 15.625rem);

    @include up('medium') {
      transform: translate(80%, 30%);
      width: rem(320px);
    }
  }
}
