@use 'vars';
@use 'mixins' as *;
// @include spacings;

body {
  font-family: var(--font-archivo);
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  font-size: 1rem;
  color: var(--color-black);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
}

main {
  flex-grow: 1;
}

.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--content-padding);
  width: 100%;
}

.overlay {
  background: var(--color-black);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
}
