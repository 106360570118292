// grid styleguides
@use 'mixins' as *;

.grid-auto {
  display: grid;
  gap: var(--gap, 1rem);
  grid-template-columns: 1fr;

  @include up('small') {
    grid-template-columns: repeat(auto-fill, minmax(var(--col-width, 5rem), 1fr));
  }
}

.grid-col {
  display: grid;
  gap: var(--gap, 1rem);
  grid-template-columns: repeat(var(--columns, 1), 1fr);
}

.wrap-large {
  width: min(100%, rem(1160px));
  margin-inline: auto;
}

.wrap {
  width: min(100%, rem(920px));
  margin-inline: auto;
}
