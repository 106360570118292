@use '../../assets/styles/mixins' as *;

.facts {
  position: relative;
  padding-top: rem(205px);
  padding-bottom: rem(150px);

  @include up('medium') {
    padding-top: rem(135px);
  }

  h2 {
    color: var(--color-white);
    font-size: 2.5rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2.5rem;

    @include up('medium') {
      font-size: 3rem;
      max-width: 40rem;
      margin-inline: auto;
      margin-bottom: 4rem;
    }
  }

  .trio {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-bottom: 5rem;

    @include up('medium') {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 11.25rem;
    }

    .trio__element {
      flex: 1;
    }

    .number {
      color: var(--color-primary);
      font-size: 5.625rem;
      font-weight: 600;
      line-height: 1;
    }

    .divider {
      height: 1px;
      background-color: var(--color-primary);
      margin-block: 1.25rem;
    }

    .text {
      color: var(--color-white);
      font-size: 1.5rem;
      font-weight: 400;
      line-height: normal;
    }
  }

  .tube-image {
    max-width: none;
    width: rem(520px);
    transform: translateX(-40%);

    @include up('large') {
      position: absolute;
      top: rem(-115px);
      left: rem(-60px);
    }
  }

  .bottle-group {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      'originator'
      'bottle'
      'biosimilar';
    margin-bottom: 10rem;
    margin-inline: auto;
    max-width: rem(500px);

    .originator,
    .biosimilar {
      display: flex;
      gap: 2.5rem;
      justify-content: space-between;
      align-items: center;

      p {
        color: var(--color-white);
        font-size: 1.5rem;
        font-weight: 400;
        line-height: normal;

        span {
          font-weight: 600;
          display: block;
        }
      }
    }

    .originator {
      grid-area: originator;
    }

    .bottle {
      grid-area: bottle;

      img {
        max-width: 12rem;
        margin-inline: auto;
      }
    }

    .biosimilar {
      grid-area: biosimilar;

      img {
        rotate: 180deg;
      }
    }

    @include up('large') {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-areas:
        'originator bottle .'
        '. bottle .'
        '. bottle biosimilar'
        '. bottle .';
      max-width: none;

      .bottle {
        img {
          max-width: 100%;
        }
      }
    }
  }

  .vital-concept {
    border-top: 1px solid var(--color-primary);

    p {
      color: var(--color-primary);
      text-align: center;
      font-weight: 600;
      line-height: normal;
      font-size: 2.4rem;
      margin-top: 2.5rem;

      @include up('medium') {
        font-size: 3.4rem;
      }
    }
  }
}
