/**
 *
 * Typography
 *
 */

@use 'vars';
@use 'mixins' as *;

.bold,
strong {
  font-weight: 700 !important;
}

.not-found {
  h2 {
    font-size: 4rem;
    margin-block: 2rem 1rem;
  }

  p {
    font-size: 1.5rem;
  }

  a {
    font-weight: 700;
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}

// h1,
// .h1 {
//   font-size: var(--size-500);
//   font-weight: var(--fw-200);
//   line-height: 1.5;
//   margin: 0 0 var(--spacing-300);
// }

// h2,
// .h2 {
//   font-size: var(--size-400);
//   font-weight: var(--fw-200);
//   line-height: 1.5;
//   margin: 0 0 var(--spacing-300);
// }

// h3,
// .h3 {
//   font-size: var(--size-300);
//   font-weight: var(--fw-200);
//   line-height: 1.5;
//   margin: 0 0 var(--spacing-300);
// }

// h4,
// .h4 {
//   font-size: var(--size-200);
//   font-weight: var(--fw-200);
//   line-height: 1.5;
//   margin: 0 0 var(--spacing-300);
// }

// h5,
// .h5 {
//   font-size: var(--size-100);
//   font-weight: var(--fw-200);
//   line-height: 1.5;
//   margin: 0 0 var(--spacing-300);
// }

// p {
//   font-size: var(--size-000);
//   font-weight: var(--fw-000);
//   margin: 0 0 1.5em;
// }

// .small,
// small {
//   font-size: var(--size-50);
//   font-weight: var(--fw-000);
// }

.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}
